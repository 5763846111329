import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import MDButton from 'components/MDButton';
import { Box } from '@mui/material';
import dayjs from 'dayjs';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: 'linear-gradient(195deg, #42424a, #191919)',
    backgroundColor: '#42424a',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [createData('Frozen yoghurt', 159)];

export default function TableCustomizedUpdate({ attendanceLogs }) {
  const ExportAttendanceLogs = () => {
    const headers = ['Date', 'Status', 'Time', 'Department', 'Shift Name'];

    const rows = attendanceLogs?.attendanceRecords?.map((row) => [
    `${dayjs(row.date).format('YYYY-MM-DD')}`,
      row.status === 'checkIn' ? 'Checked In' : 'Checked Out',
      row.time,
      attendanceLogs?.department,
      attendanceLogs?.shift?.shiftName,
    ]);

    const csvContent = [headers, ...rows]
      .map((e) => e.join(','))
      .join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'attendance_logs.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <Box
      sx={{
        mt: 10,
      }}
    >
      <TableContainer
        sx={{
          border: '1px solid #42424a',
          padding: '10px',
        }}
        component={Paper}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            mb: 2,
          }}
        >
          {/* <MDButton onClick={ExportAttendanceLogs} variant="contained" color="info">
            Download Attendance Logs
          </MDButton> */}
        </Box>

        <Table sx={{ minWidth: 400 }} aria-label="customized table">
          <TableHead
            sx={{
              display: 'contents',
              width: '100%',
            }}
          >
            <TableRow>
              <StyledTableCell align="center">Date</StyledTableCell>
              <StyledTableCell align="center">Status</StyledTableCell>
              <StyledTableCell align="center">Time</StyledTableCell>
              <StyledTableCell align="center">Department</StyledTableCell>
              <StyledTableCell align="center">Shift Name</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {attendanceLogs?.attendanceRecords?.map((row, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell align="center">
                  {row.date}
                  {/* {new Date(row.time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} */}
                </StyledTableCell>
                <StyledTableCell align="center" component="th" scope="row">
                  {row.status === 'checkIn' ? 'Checked In' : 'Checked Out'}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {row.time}
                  {/* {new Date(row.time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} */}
                </StyledTableCell>
                <StyledTableCell align="center">{attendanceLogs?.department}</StyledTableCell>
                <StyledTableCell align="center">{attendanceLogs?.shift?.shiftName}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
