import React, { useState, useEffect, useContext } from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import DataTable from 'examples/Tables/DataTable';
import apiService from '../../services/ApiService';
import { toast } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import Switch from '@mui/material/Switch';
import { AuthContext } from 'context/AuthContext';

function PendingUsers() {
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const { user, permission } = useContext(AuthContext);

  const { HrManager } = permission;

  // Fetch Pending Users
  useEffect(() => {
    const fetchPendingUsers = async () => {
      const departmentId = user?.department?._id;
      const userId = user?._id;

      if (!departmentId || !userId) return;

      try {
        const response = await apiService.post('/api/v1/users/pendingUsersList', {
          departmentId,
          userId,
        });

        if (response.status === 200) {
          const pendingUsers = response.data.data;

          {
            HrManager
              ? setColumns([
                  { Header: 'Full Name', accessor: 'fullName', align: 'left' },
                  { Header: 'Email', accessor: 'email', align: 'left' },
                  { Header: 'Person Number', accessor: 'personNumber', align: 'left' },
                  { Header: 'Department', accessor: 'department', align: 'left' },
                  { Header: 'Approved', accessor: 'isApproved', align: 'center' },
                ])
              : setColumns([
                  { Header: 'Full Name', accessor: 'fullName', align: 'left' },
                  { Header: 'Email', accessor: 'email', align: 'left' },
                  { Header: 'Person Number', accessor: 'personNumber', align: 'left' },
                  { Header: 'Department', accessor: 'department', align: 'left' },
                  { Header: 'Roles', accessor: 'roles', align: 'left' },
                  { Header: 'Approved', accessor: 'isApproved', align: 'center' },
                  { Header: 'Actions', accessor: 'actions', align: 'center' },
                ]);
          }

          const formattedRows = pendingUsers.map((user) => ({
            id: user._id,
            fullName: (
              <Link to={`/users/view/${user._id}`}>
                <MDTypography display="block" variant="button" fontWeight="medium">
                  {user.fullName || 'N/A'}
                </MDTypography>
              </Link>
              // <MDTypography display="block" variant="button" fontWeight="medium">
              //   {user.fullName}
              // </MDTypography>
            ),
            email: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {user.email}
              </MDTypography>
            ),
            personNumber: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {user.personNumber || 'N/A'}
              </MDTypography>
            ),
            department: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {user.department?.name || 'N/A'}
              </MDTypography>
            ),

            roles: !HrManager ? (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {user.roles?.map((role) => role.name).join(', ') || 'N/A'}
              </MDTypography>
            ) : null,
            isApproved: HrManager ? (
              <Switch
                checked={user.isApproved}
                onChange={() => handleToggleApproval(user._id, !user.isApproved)}
                color="primary"
              />
            ) : null,
            actions: !HrManager ? (
              <MDBox display="flex" alignItems="center" justifyContent="center">
                <Link to={`/users/view/${user._id}`}>
                  <MDTypography
                    component="span"
                    variant="caption"
                    color="text"
                    fontWeight="medium"
                    sx={{ mx: 1 }}
                  >
                    View Details
                  </MDTypography>
                </Link>
              </MDBox>
            ) : null,
          }));

          setRows(formattedRows);
        }
      } catch (error) {
        toast.error('Failed to fetch pending users');
      }
    };

    fetchPendingUsers();
  }, [user]);

  // Handle approval toggle
  const handleToggleApproval = async (userId, newStatus) => {
    try {
      const response = await apiService.put(`/api/v1/users/update/${userId}`, {
        isApproved: newStatus,
      });

      if (response.status === 200) {
        toast.success(newStatus ? 'User Approved' : 'User Rejected');
        setRows((prevRows) =>
          prevRows.map((row) =>
            row.id === userId
              ? {
                  ...row,
                  isApproved: (
                    <Switch
                      checked={newStatus}
                      onChange={() => handleToggleApproval(userId, !newStatus)}
                      color="primary"
                    />
                  ),
                }
              : row
          )
        );
      }
    } catch (error) {
      toast.error('Failed to Update');
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <MDTypography variant="h6" color="white">
                  Pending Users
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                {rows.length === 0 ? ( // Check if rows array is empty
                  <MDBox display="flex" alignItems="center" justifyContent="center" height="200px">
                    <MDTypography variant="h6" color="text">
                      No Pending User Found.
                    </MDTypography>
                  </MDBox>
                ) : (
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                  />
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default PendingUsers;
