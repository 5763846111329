import React, { useState, useEffect, useContext } from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import DataTable from 'examples/Tables/DataTable';
import apiService from '../../services/ApiService';
import { toast } from 'react-hot-toast';
import IconButton from '@mui/material/IconButton';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/AddBox';
import Switch from '@mui/material/Switch';
import { AuthContext } from '../../context/AuthContext';
import { MenuItem, Select, Checkbox, ListItemText, FormControl, TextField } from '@mui/material';
import { Approval, DeleteOutline, Edit } from '@mui/icons-material';

function ImportedUsers() {
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [roles, setRoles] = useState([]);
  const [isApproved, setIsApproved] = useState(false);
  const { permission } = useContext(AuthContext);
  const { Admin = false } = permission;
  const [searchQuery, setSearchQuery] = useState('');

  // Fetch Roles
  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const rolesResponse = await apiService.get('/api/v1/roles/list');
        console.log('rolesResponse', rolesResponse);
        if (rolesResponse.status === 200) {
          setRoles(rolesResponse.data.roles);
        }
      } catch (error) {
        toast.error('Failed to fetch roles');
      }
    };
    fetchRoles();
  }, []);

  // Fetch Users
  useEffect(() => {
    fetchUsersData();
  }, [roles, isApproved]);

  const fetchUsersData = async () => {
    try {
      const usersResponse = await apiService.get('/api/v1/importUsers/list');
      console.log('usersResponse', usersResponse);
      if (usersResponse?.data?.status === 200) {
        const users = usersResponse.data.data;

        // Update columns with the additional fields
        setColumns([
          { Header: 'Full Name', accessor: 'full_name', align: 'left' },
          { Header: 'Person No.', accessor: 'person_number', align: 'left' },
          { Header: 'Legal Employer', accessor: 'legal_employer', align: 'left' },
          { Header: 'Business Unit', accessor: 'business_unit', align: 'left' },
          { Header: 'Department', accessor: 'department', align: 'left' },
          { Header: 'Manager Name', accessor: 'manager_name', align: 'left' },
          { Header: 'Manager Email', accessor: 'manager_email', align: 'left' },
          { Header: 'Position', accessor: 'position', align: 'left' },
        ]);

        // Format rows to include the additional fields
        const formattedRows = users.map((user) => ({
          id: user._id,
          full_name: (
            <Link to={`/imported-users/${user._id}`}>
              <MDTypography display="block" variant="button" fontWeight="medium">
                {user.full_name || 'N/A'}
              </MDTypography>
            </Link>
          ),
          person_number: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {user.person_number || 'N/A'}
            </MDTypography>
          ),
          legal_employer: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {user.legal_employer || 'N/A'}
            </MDTypography>
          ),
          business_unit: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {user.business_unit || 'N/A'}
            </MDTypography>
          ),
          department: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {user.department || 'N/A'}
            </MDTypography>
          ),
          manager_name: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {user.manager_name || 'N/A'}
            </MDTypography>
          ),
          manager_email: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {user.manager_email || 'N/A'}
            </MDTypography>
          ),
          position: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {user.position || 'N/A'}
            </MDTypography>
          ),
        }));

        setRows(formattedRows);
        setFilteredRows(formattedRows);
      }
    } catch (error) {
      toast.error('Failed to fetch users');
    }
  };

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    // Filter rows based on full_name or person_number
    const newFilteredRows = rows.filter((row) => {
      const fullName = row.full_name.props.children.props.children?.toLowerCase() || '';
      const personNumber = row.person_number.props.children?.toLowerCase() || '';
      return fullName.includes(query) || personNumber.includes(query);
    });

    setFilteredRows(newFilteredRows);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <MDTypography variant="h6" color="white">
                  Imported Users
                </MDTypography>
                <Link to={'/users/create'}>
                  {!Admin && (
                    <IconButton color="white">
                      <AddIcon />
                    </IconButton>
                  )}
                </Link>
              </MDBox>
              <MDBox p={2} display="flex" justifyContent="flex-end">
                <TextField
                  label="Search"
                  variant="outlined"
                  value={searchQuery}
                  onChange={handleSearch}
                  size="small"
                />
              </MDBox>
              <MDBox pt={3}>
                {filteredRows.length > 0 ? (
                  <DataTable
                    table={{ columns, rows: filteredRows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                ) : (
                  <MDBox display="flex" alignItems="center" justifyContent="center" p={3}>
                    <MDTypography variant="caption" color="text" fontWeight="medium">
                      No User Found
                    </MDTypography>
                  </MDBox>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default ImportedUsers;
