import React, { useEffect, useState, useContext } from 'react';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import MDBox from 'components/MDBox';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';
// import ProfileInfoCard from 'examples/Cards/InfoCards/ProfileInfoCard';
import ProfilesList from 'examples/Lists/ProfilesList';
import Header from 'layouts/profile/components/Header';
import apiService from '../../services/ApiService';
import { AuthContext } from '../../context/AuthContext';
import { Business } from '@mui/icons-material';
import MDTypography from 'components/MDTypography';
import MDAvatar from 'components/MDAvatar';
import { Card } from '@mui/material';
import NewProfileCard from 'examples/Cards/InfoCards/NewProfileCard';

function Overview() {
  // const [profiles, setProfiles] = useState([]);
  const { user, permission } = useContext(AuthContext);
  const { Admin = false, HrManager = false, HRBP = false } = permission;
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <MDTypography variant="h6" color="white">
                 Profile
                </MDTypography>
              </MDBox>
              <MDBox pt={5} pb={3} px={5}>
                <Grid container spacing={4}>
                  {/* Profile Image */}
                  <Grid item xs={12} md={4} lg={2} justifyContent="center">
                    <MDBox sx={{}}>
                      <MDTypography
                        style={{
                          marginBottom: '15px',
                        }}
                        variant="h6"
                        color="textPrimary"
                      >
                        Profile Image
                      </MDTypography>
                      <MDAvatar
                        src={`https://babtainnotify.com:81/${user.profileImage}`}
                        alt={user.fullName}
                        sx={{ width: 150, height: 150, boxShadow: 3 }}
                      />
                    </MDBox>
                    
                  </Grid>
                  <Grid item xs={12} md={8} lg={10}>
                    <Card>
                      <NewProfileCard
                        title="Profile Information"
                        info={{
                          fullName: user?.fullName || 'N/A',
                          email: user?.email || 'N/A',
                          personNumber: user?.personNumber || 'N/A',
                          createdAt: new Date(user?.createdAt).toLocaleDateString(),
                          department: user?.department?.name || 'N/A',
                          departmentLocation: user?.department?.address || 'N/A',
                          businessUnit: user?.businessUnit || 'N/A',
                          role: user?.roles?.map((role) => role.name).join(', ') || 'N/A',
                        }}
                        shadow={false}
                      />
                    </Card>
                  
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Overview;
