import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import { useNavigate, useParams } from 'react-router-dom';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import apiService from '../../services/ApiService';
import { toast } from 'react-hot-toast';

const defaultCenter = { lat: 29.336251146109127, lng: 47.93002145996093 };

function Edit() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: '',
    businessUnits: [],
    radius: '',
    location: { lat: defaultCenter.lat, lng: defaultCenter.lng },
    address: '', // Added address field
    shiftId: '',
  });
  const [businessUnitOptions, setBusinessUnitOptions] = useState([]);
  const [mapCenter, setMapCenter] = useState(defaultCenter);
  const [shifts, setShifts] = useState([]);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyDctfNdridCgXmF6tQw4ef1tkcteV7VT7A',
  });

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const response = await apiService.get('/api/v1/businessUnits/list');
        if (response.status === 200) {
          setBusinessUnitOptions(
            response.data.businessUnits.map((unit) => ({
              value: unit._id,
              label: unit.name,
            }))
          );
        }

        const departmentResponse = await apiService.get(`/api/v1/departments/detail/${id}`);
        if (departmentResponse.status === 200) {
          const department = departmentResponse.data.department;
          console.log('department', department)
          setFormData({
            name: department.name,
            businessUnits: department.businessUnits.map((bu) => bu.businessUnit),
            radius: department.radius,
            location: department.location || { lat: defaultCenter.lat, lng: defaultCenter.lng },
            address: department.address || '', // Load existing address
            shiftId: department.shift?._id|| '',
          });
          setMapCenter(department.location || defaultCenter);
        }
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };

    fetchOptions();
  }, [id]);

  const fetchAddress = async (lat, lng) => {
    // Fetch address using Google Maps API or any other geocoding service
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyDctfNdridCgXmF6tQw4ef1tkcteV7VT7A`
      );
      const data = await response.json();
      if (data.results.length > 0) {
        setFormData((prevData) => ({
          ...prevData,
          address: data.results[0].formatted_address,
        }));
      }
    } catch (error) {
      console.error('Failed to fetch address:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleBusinessUnitChange = (event) => {
    setFormData({
      ...formData,
      businessUnits: event.target.value,
    });
  };

  const handleMarkerDragEnd = async (e) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    setFormData((prevData) => ({
      ...prevData,
      location: { lat, lng },
    }));
    setMapCenter({ lat, lng });
    await fetchAddress(lat, lng); // Fetch and update address
  };

  const handleCurrentLocation = async () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          setFormData((prevData) => ({
            ...prevData,
            location: { lat: latitude, lng: longitude },
          }));
          setMapCenter({ lat: latitude, lng: longitude });
          await fetchAddress(latitude, longitude); // Fetch and update address
        },
        (error) => {
          console.error('Error getting location:', error);
        }
      );
    } else {
      console.log('Geolocation is not supported by this browser.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await apiService.put(`/api/v1/departments/update/${id}`, {
        name: formData.name,
        businessUnits: formData.businessUnits,
        location: formData.location,
        radius: formData.radius,
        address: formData.address, // Include address in the request
        shiftId: formData.shiftId,
      });

      if (response.status === 200) {
        navigate('/departments');
        toast.success('Department Updated.');
      }
    } catch (error) {
      console.error('Failed to update department:', error);
    }
  };

  const getShifts = async () => {
    try {
      const response = await apiService.get('/api/v1/shifts/list');

      if (response.status === 200) {
        setShifts(response.data.shifts);
      }
    } catch (error) {
      toast.error('Failed to fetch shifts');
      console.error('Failed to fetch shifts:', error);
    }
  };

  useEffect(() => {
    getShifts();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <MDTypography variant="h6" color="white">
                  Edit Department
                </MDTypography>
              </MDBox>
              <MDBox pt={3} px={2} component="form" onSubmit={handleSubmit}>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="Department Name"
                    name="name"
                    variant="standard"
                    fullWidth
                    value={formData.name}
                    onChange={handleInputChange}
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    type="number"
                    label="Radius (meters)"
                    name="radius"
                    variant="standard"
                    fullWidth
                    value={formData.radius}
                    onChange={handleInputChange}
                  />
                </MDBox>

                <MDBox mb={2}>
                  <InputLabel id="shift-id-label">Select Shift *</InputLabel>
                  <Select
                    labelId="shift-id-label"
                    name="shiftId"
                    value={formData.shiftId}
                    onChange={handleInputChange}
                    required
                    fullWidth
                    variant="standard"
                  >
                    {shifts.map((shift) => (
                      <MenuItem key={shift.shiftName} value={shift._id}>
                        {shift.shiftName}
                      </MenuItem>
                    ))}
                  </Select>
                </MDBox>
                <MDBox mb={2}>
                  <FormControl fullWidth>
                    <InputLabel id="business-units-label">Select Business Units</InputLabel>
                    <Select
                      labelId="business-units-label"
                      multiple
                      variant="standard"
                      value={formData.businessUnits}
                      onChange={handleBusinessUnitChange}
                      renderValue={(selected) =>
                        selected
                          .map(
                            (id) => businessUnitOptions.find((option) => option.value === id)?.label
                          )
                          .join(', ')
                      }
                    >
                      {businessUnitOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          <Checkbox checked={formData.businessUnits.includes(option.value)} />
                          <ListItemText primary={option.label} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </MDBox>
                <MDBox mb={2}>
                  <MDTypography variant="h6">Update Location</MDTypography>
                  {isLoaded && (
                    <GoogleMap
                      center={mapCenter}
                      zoom={15}
                      mapContainerStyle={{ height: '400px', width: '100%' }}
                    >
                      <Marker
                        position={formData.location}
                        draggable
                        onDragEnd={handleMarkerDragEnd}
                      />
                    </GoogleMap>
                  )}
                </MDBox>
                <MDBox mt={4} mb={1}>
                  <MDButton variant="outlined" color="info" onClick={handleCurrentLocation}>
                    Use Current Location
                  </MDButton>
                </MDBox>
                <MDBox mt={4} mb={1}>
                  <MDButton variant="gradient" color="info" type="submit">
                    Save Changes
                  </MDButton>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Edit;
