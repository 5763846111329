import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import apiService from '../../services/ApiService';
import { Box, Button, InputLabel, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const ShiftsData = [
  {
    label: 'Single Shift',
    value: 'SS',
  },
  {
    label: 'Double Shift',
    value: 'DS',
  },
  {
    label: 'Over Night',
    value: 'ON',
  },
];

function Create() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    shiftName: '',
    shiftType: '',
    startTime: null,
    endTime: null,
    // description: "",
    // legalEntityNumber: "",
    // code: "",
  });

  console.log('formData', formData);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleTimeChange = (name, newValue) => {
    setFormData({
      ...formData,
      [name]: newValue,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('hello');

    if (!formData.startTime || !formData.endTime) {
      toast.error('Please select both start and end times.');
      return;
    }
    const payload = {
      ...formData,
      startTime: formData.startTime.toISOString(),
      endTime: formData.endTime.toISOString(),
    };
    try {
      const response = await apiService.post('/api/v1/shifts/create', payload);
      if (response.status === 201) {
        toast.success(response?.data?.message);
        navigate('/shifts');
      }
    } catch (error) {
      toast.error(`${error.response.data.message}`);
      console.error('Error creating business unit:', error);
    }
  };

 

  return (
    <DashboardLayout>
  <DashboardNavbar />
  <MDBox pt={6} pb={3}>
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Card>
          <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <MDTypography variant="h6" color="white">
              Create Shift
            </MDTypography>
          </MDBox>
          <MDBox pt={3} px={2} component="form" onSubmit={handleSubmit}>
            {/* Shift Name Field */}
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Shift Name *"
                name="shiftName"
                variant="standard"
                fullWidth
                value={formData.shiftName}
                onChange={handleInputChange}
              />
            </MDBox>

            {/* Select Shift Type */}
            <MDBox mb={2}>
              <InputLabel id="shift-type-label">Select Shift Type *</InputLabel>
              <Select
                labelId="shift-type-label"
                name="shiftType"
                value={formData.shiftType}
                onChange={handleInputChange}
                fullWidth
                variant="standard"
              >
                {ShiftsData.map((shift) => (
                  <MenuItem key={shift.label} value={shift.value}>
                    {shift.label}
                  </MenuItem>
                ))}
              </Select>
            </MDBox>

            {/* Start Time Picker */}
            <MDBox mb={2}>
              <TimePicker
                label="Start Time *"
                value={formData.startTime}
                onChange={(newValue) => handleTimeChange('startTime', newValue)}
                renderInput={(params) => <MDInput {...params} variant="standard" fullWidth />}
              />
            </MDBox>

            {/* End Time Picker */}
            <MDBox mb={2}>
              <TimePicker
                label="End Time *"
                value={formData.endTime}
                onChange={(newValue) => handleTimeChange('endTime', newValue)}
                renderInput={(params) => <MDInput {...params} variant="standard" fullWidth />}
              />
            </MDBox>

            {/* Submit Button */}
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" type="submit" >
                Create
              </MDButton>
            </MDBox>
          </MDBox>
        </Card>
      </Grid>
    </Grid>
    </LocalizationProvider>
  </MDBox>
</DashboardLayout>

  );
}

export default Create;

{
  /* <MDBox pt={6} pb={3}>
  <Grid container spacing={6}>
    <Grid item xs={12}>
      <Card>
        <MDBox
          mx={2}
          mt={-3}
          py={3}
          px={2}
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <MDTypography variant="h6" color="white">
            Create Slot
          </MDTypography>
        </MDBox>
        <MDBox pt={3} px={2} component="form" onSubmit={handleSubmit}>
          <MDBox mb={2}>
            <MDInput
              type="text"
              label="Shift Name *"
              name="shiftName"
              variant="standard"
                sx={{
                  width:'50%'
                }}
              value={formData.name}
              onChange={handleInputChange}
            />
          </MDBox>

          <MDBox mb={2}>
            <InputLabel id="shift-label">Select Shift Type *</InputLabel>
            <Select
              labelId="shift-label"
              name="shiftType"
              value={formData.shiftType}
              onChange={handleInputChange}
              fullWidth
              variant="standard"
            >
              {ShiftsData.map((shift) => (
                <MenuItem key={shift.label} value={shift.value}>
                  {shift.label}
                </MenuItem>
              ))}
            </Select>
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="time"
              label="Start Time *"
              name="startTime"
              variant="standard"
              fullWidth
              value={formData.startTime}
              onChange={handleInputChange}
            />
          </MDBox>

         
          <MDBox mb={2}>
            <MDInput
              type="time"
              label="End Time *"
              name="endTime"
              variant="standard"
              fullWidth
              value={formData.endTime}
              onChange={handleInputChange}
            />
          </MDBox>
          <MDBox mt={4} mb={1}>
            <MDButton variant="gradient" color="info" type="submit">
              Create
            </MDButton>
          </MDBox>
        </MDBox>
      </Card>
    </Grid>
  </Grid>
</MDBox> */
}
