import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import MDButton from 'components/MDButton';
import { Box } from '@mui/material';
import dayjs from 'dayjs';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: 'linear-gradient(195deg, #42424a, #191919)',
    backgroundColor: '#42424a',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [createData('Frozen yoghurt', 159)];

export default function AdminCustomized({ attendanceLogs }) {
  const ExportAttendanceLogs = () => {
    const headers = ['Name', 'Date', 'Status', 'Time', 'Department', 'Shift Name'];
  
    const rows = attendanceLogs?.report?.flatMap((user) => {
      if (user.attendanceRecords.length > 0) {
        return user.attendanceRecords.map((record, index) => [
          index === 0 ? user.user.name : '', // Show name only for the first record
          `${dayjs(record.date).format('YYYY-MM-DD')}\t`, // Add `\t` to fix the Excel issue
          record.status === 'checkIn' ? 'Checked In' : 'Checked Out',
          record.time,
          user.department,
          user.shift.shiftName,
        ]);
      } else {
        return [
          [
            user.user.name,
            'N/A\t', // Add `\t` for consistency
            'N/A',
            'N/A',
            user.department,
            user.shift.shiftName,
          ],
        ];
      }
    });
  
    const csvContent = [headers, ...rows]
      .map((row) => row.join(','))
      .join('\n');
  
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'attendance_logs.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  

  return (
    <Box
      sx={{
        mt: 10,
      }}
    >
      <TableContainer
        sx={{
          border: '1px solid #42424a',
          padding: '10px',
        }}
        component={Paper}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            mb: 2,
          }}
        >
          {/* <MDButton onClick={ExportAttendanceLogs} variant="contained" color="info">
            Download Attendance Logs
          </MDButton> */}
        </Box>

        <Table sx={{ minWidth: 400 }} aria-label="customized table">
          <TableHead
            sx={{
              display: 'contents',
              width: '100%',
            }}
          >
            <TableRow>
              <StyledTableCell align="center">User Name</StyledTableCell>
              <StyledTableCell align="center">Date</StyledTableCell>
              <StyledTableCell align="center">Status</StyledTableCell>
              <StyledTableCell align="center">Time</StyledTableCell>
              <StyledTableCell align="center">Department</StyledTableCell>
              <StyledTableCell align="center">Shift Name</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {attendanceLogs?.report?.map((row, index) => (
              <>
                {row?.attendanceRecords?.length > 0 ? (
                  row.attendanceRecords.map((item, idx) => (
                    <StyledTableRow key={`${index}-${idx}`}>
                      {/* User Name */}
                      {idx === 0 && (
                        <StyledTableCell align="center" rowSpan={row.attendanceRecords.length}>
                          {row?.user?.name}
                        </StyledTableCell>
                      )}

                      {/* Attendance Record Details */}
                      <StyledTableCell align="center">{item.date}</StyledTableCell>
                      <StyledTableCell align="center">
                        {item.status === 'checkIn' ? 'Checked In' : 'Checked Out'}
                      </StyledTableCell>
                      <StyledTableCell align="center">{item.time}</StyledTableCell>

                      {/* Department and Shift Name */}
                      {idx === 0 && (
                        <>
                          <StyledTableCell align="center" rowSpan={row.attendanceRecords.length}>
                            {row?.department || 'N/A'}
                          </StyledTableCell>
                          <StyledTableCell align="center" rowSpan={row.attendanceRecords.length}>
                            {row?.shift?.shiftName || 'N/A'}
                          </StyledTableCell>
                        </>
                      )}
                    </StyledTableRow>
                  ))
                ) : (
                  <StyledTableRow key={index}>
                    {/* User Name */}
                    <StyledTableCell align="center">{row?.user?.name}</StyledTableCell>

                    {/* N/A for Attendance Record */}
                    <StyledTableCell align="center">N/A</StyledTableCell>
                    <StyledTableCell align="center">N/A</StyledTableCell>
                    <StyledTableCell align="center">N/A</StyledTableCell>

                    {/* Department and Shift Name */}
                    <StyledTableCell align="center">{row?.department || 'N/A'}</StyledTableCell>
                    <StyledTableCell align="center">
                      {row?.shift?.shiftName || 'N/A'}
                    </StyledTableCell>
                  </StyledTableRow>
                )}
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
