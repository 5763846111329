import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import MDButton from 'components/MDButton';
import { Box } from '@mui/material';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#42424a',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const formatTime = (time) => {
  if (!time) return 'N/A';
  return new Date(time).toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  });
};


export default function AttendanceAdmin({ attendanceLogs }) {
  const ExportAttendanceLogs = () => {
    const headers = [
      'Department',
      'Employee Name',
      'Employee Number',
      'Date',
      'Shift Name',
      'Shift Start Time',
      'Shift End Time',
      'Check In Time',
      'Check Out Time',
      'Schedule Duration',
      'Actual Duration',
      'Status',
    ];
  
    const rows = attendanceLogs?.report?.flatMap((user) =>
      user.attendanceRecords.map((record) => {
        const checkInTimes = record.checkIn?.length ? record.checkIn.join(', ') : 'N/A';
        const checkOutTimes = record.checkOut?.length ? record.checkOut.join(', ') : 'N/A';
  
        // Check if the time is a valid string instead of using new Date()
        const formatTime = (time) => (time ? time : 'N/A');
  
        return [
          user.department || 'N/A',
          user.user?.name || 'N/A',
          user.user?.personNumber || 'N/A',
          record.date || 'N/A',
          record.shift?.shiftName || 'N/A',
          formatTime(record.shift?.startTime),
          formatTime(record.shift?.endTime),
          checkInTimes,
          checkOutTimes,
          record.shift?.scheduleDuration || 'N/A',
          record.actual_duration || 'N/A',
          record.status || 'N/A',
        ];
      })
    );
  
    const csvContent = [headers, ...rows].map((row) => row.join(',')).join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'attendance_logs.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  

  return (
    <Box sx={{ mt: 10 }}>
      <TableContainer
        sx={{
          border: '1px solid #42424a',
          padding: '10px',
        }}
        component={Paper}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            mb: 2,
          }}
        >
          <MDButton onClick={ExportAttendanceLogs} variant="contained" color="info">
            Download Attendance Report
          </MDButton>
        </Box>

        <div style={{ overflowX: 'auto' }}>
  <Table aria-label="customized table">
    <TableHead
      sx={{
        display: 'contents',
        width: '100%',
        th: {
          whiteSpace: 'nowrap',
        },
      }}
    >
      <TableRow>
        <StyledTableCell align="center">Department</StyledTableCell>
        <StyledTableCell align="center">Employee Name</StyledTableCell>
        <StyledTableCell align="center">Employee Number</StyledTableCell>
        <StyledTableCell align="center">Date</StyledTableCell>
        <StyledTableCell align="center">Shift Name</StyledTableCell>
        <StyledTableCell align="center">Shift Start Time</StyledTableCell>
        <StyledTableCell align="center">Shift End Time</StyledTableCell>
        <StyledTableCell align="center">Check In Time</StyledTableCell>
        <StyledTableCell align="center">Check Out Time</StyledTableCell>
        <StyledTableCell align="center">Schedule Duration</StyledTableCell>
        <StyledTableCell align="center">Actual Duration</StyledTableCell>
        <StyledTableCell align="center">Status</StyledTableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {attendanceLogs?.report?.map((row, index) =>
        row.attendanceRecords.map((record, idx) => (
          <StyledTableRow key={`${index}-${idx}`}>
            <StyledTableCell sx={{ whiteSpace: 'nowrap' }} align="center">{row?.department || 'N/A'}</StyledTableCell>
            <StyledTableCell  sx={{ whiteSpace: 'nowrap' }}align="center">{row?.user?.name || 'N/A'}</StyledTableCell>
            <StyledTableCell sx={{ whiteSpace: 'nowrap' }} align="center">{row?.user?.personNumber || 'N/A'}</StyledTableCell>
            <StyledTableCell sx={{ whiteSpace: 'nowrap' }} align="center">{record.date || 'N/A'}</StyledTableCell>
            <StyledTableCell sx={{ whiteSpace: 'nowrap' }} align="center">{record?.shift?.shiftName || 'N/A'}</StyledTableCell>
            <StyledTableCell sx={{ whiteSpace: 'nowrap' }}  align="center">{record?.shift?.startTime || 'N/A'}</StyledTableCell>
            <StyledTableCell  sx={{ whiteSpace: 'nowrap' }} align="center">{record?.shift?.endTime || 'N/A'}</StyledTableCell>
            <StyledTableCell  sx={{ whiteSpace: 'nowrap' }} align="center">
              {record?.checkIn?.length > 0 ? record.checkIn.join(', ') : 'N/A'}
            </StyledTableCell>
            <StyledTableCell sx={{ whiteSpace: 'nowrap' }} align="center">
              {record?.checkOut?.length > 0 ? record.checkOut.join(', ') : 'N/A'}
            </StyledTableCell>
            <StyledTableCell sx={{ whiteSpace: 'nowrap' }} align="center">
              {record?.shift?.scheduleDuration || 'N/A'}
            </StyledTableCell>
            <StyledTableCell sx={{ whiteSpace: 'nowrap' }} align="center">{record.actual_duration || 'N/A'}</StyledTableCell>
            <StyledTableCell sx={{ whiteSpace: 'nowrap' }} align="center">{record.status || 'N/A'}</StyledTableCell>
          </StyledTableRow>
        ))
      )}
    </TableBody>
  </Table>
</div>

      </TableContainer>
    </Box>
  );
}
