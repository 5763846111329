import { useState, useEffect, useMemo, useContext } from 'react';

// react-router components
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';

// @mui material components
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Icon from '@mui/material/Icon';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';

// Material Dashboard 2 React example components
import Sidenav from 'examples/Sidenav';
import Configurator from 'examples/Configurator';

// Material Dashboard 2 React themes
import theme from 'assets/theme';
import themeRTL from 'assets/theme/theme-rtl';

// Material Dashboard 2 React Dark Mode themes
import themeDark from 'assets/theme-dark';
import themeDarkRTL from 'assets/theme-dark/theme-rtl';

// RTL plugins
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';

// Material Dashboard 2 React routes
import UseRoutes from './routes';

// Material Dashboard 2 React contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from 'context';

// Images
import brandWhite from 'assets/images/logo-ct.png';
import brandDark from 'assets/images/logo-ct-dark.png';
import CompanyCreate from 'layouts/companies/create';
import CompanyEdit from 'layouts/companies/edit';
import BusinessCreate from 'layouts/business/create';
import BusinessEdit from 'layouts/business/edit';
import ShiftCreate from 'layouts/shift/create';
import ShiftEdit from 'layouts/shift/edit';
import DepartmentCreate from 'layouts/departments/create';
import DepartmentEdit from 'layouts/departments/edit';
import DepartmentDetail from 'layouts/departments/detail';
import DepartmentFetchLocation from 'layouts/departments/fetchLocation';
import UserEdit from 'layouts/users/edit';
import RoleCreate from 'layouts/roles/create';
import RoleEdit from 'layouts/roles/edit';
import UserDetails from 'layouts/users/UserDetails';
import { AuthContext } from 'context/AuthContext';
import PrivacyPolicy from 'layouts/privacy/index';
import SignIn from 'layouts/authentication/sign-in';
import SignUp from 'layouts/authentication/sign-up';
import CompanyDetail from 'layouts/companies/CompanyDetail';
import ImportedDetail from 'layouts/users/ImportedDetail';

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const [loggedInUser, setLoggedInUser] = useState(null);
  const { pathname } = useLocation();
  const { user, setUser } = useContext(AuthContext);
  const routes = UseRoutes();

  console.log('userContext', user);
  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: 'rtl',
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  useEffect(() => {
    const user = localStorage.getItem('user');
    if (user) {
      const userExist = JSON.parse(user);

      if (userExist) {
        setLoggedInUser(userExist);
        setUser(userExist);
      }
    }
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute('dir', direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes
      .filter((el) => el.permission)
      .map((route) => {
        if (route.collapse) {
          return getRoutes(route.collapse);
        }
        if (route.route) {
          return <Route exact path={route.route} element={route.component} key={route.key} />;
        }

        return null;
      });

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: 'pointer' }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  return direction === 'rtl' ? (
    <CacheProvider value={rtlCache}>
      <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
        <CssBaseline />
        {layout === 'dashboard' && (
          <>
            <Sidenav
              color={sidenavColor}
              // brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
              brandName="BABTAIN NOTIFY"
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Configurator />
            {configsButton}
          </>
        )}
        {layout === 'vr' && <Configurator />}

        <Routes>
          {user ? (
            <>
              {getRoutes(routes)}
              <Route path="/companies/create" element={<CompanyCreate />} />
              <Route path="/companies/edit/:id" element={<CompanyEdit />} />
              <Route path="/companies/view/:id" element={<CompanyDetail />} />
              <Route path="/business/create" element={<BusinessCreate />} />
              <Route path="/business/edit/:id" element={<BusinessEdit />} />
              <Route path="/shift/create" element={<ShiftCreate />} />
              <Route path="/shift/edit/:id" element={<ShiftEdit />} />
              <Route path="/departments/create" element={<DepartmentCreate />} />
              <Route path="/departments/edit/:id" element={<DepartmentEdit />} />
              <Route path="/departments/:id" element={<DepartmentDetail />} />
              <Route path="/fetchLocation" element={<DepartmentFetchLocation />} />
              {/* <Route path="/departments/fetchLocation" element={<DepartmentFetchLocation />} /> */}
              <Route path="/users/view/:id" element={<UserDetails />} />
              <Route path="/imported-users/:id" element={<ImportedDetail />} />
              <Route path="/users/edit/:id" element={<UserEdit />} />
              <Route path="/roles/create" element={<RoleCreate />} />
              <Route path="/roles/edit/:id" element={<RoleEdit />} />
              <Route path="*" element={<Navigate to="/dashboard" />} />
            </>
          ) : (
            <>
              <Route path="/authentication/sign-in" element={<SignIn />} />
              <Route path="/test/hello" element={<SignIn />} />
              <Route path="/fetchLocation" element={<DepartmentFetchLocation />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/authentication/sign-up" element={<SignUp />} />
              <Route path="*" element={<Navigate to="/authentication/sign-in" />} />
            </>
          )}
        </Routes>
      </ThemeProvider>
    </CacheProvider>
  ) : (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === 'dashboard' && (
        <>
          {user && (
            <>
              <Sidenav
                color={sidenavColor}
                // brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
                brandName="BABTAIN NOTIFY"
                routes={routes.filter((el) => el.permission)}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              <Configurator />
              {configsButton}
            </>
          )}
        </>
      )}
      {layout === 'vr' && <Configurator />}
      <Routes>
        {user ? (
          <>
            {getRoutes(routes)}
            <Route path="/companies/create" element={<CompanyCreate />} />
            <Route path="/companies/edit/:id" element={<CompanyEdit />} />
            <Route path="/companies/view/:id" element={<CompanyDetail />} />
            <Route path="/business/create" element={<BusinessCreate />} />
            <Route path="/business/edit/:id" element={<BusinessEdit />} />
            <Route path="/shift/create" element={<ShiftCreate />} />
            <Route path="/shift/edit/:id" element={<ShiftEdit />} />
            <Route path="/departments/create" element={<DepartmentCreate />} />
            <Route path="/departments/edit/:id" element={<DepartmentEdit />} />
            <Route path="/departments/:id" element={<DepartmentDetail />} />
            <Route path="/fetchLocation" element={<DepartmentFetchLocation />} />
            {/* <Route path="/departments/fetchLocation" element={<DepartmentFetchLocation />} /> */}
            <Route path="/users/view/:id" element={<UserDetails />} />
            <Route path="/users/edit/:id" element={<UserEdit />} />
            <Route path="/imported-users/:id" element={<ImportedDetail />} />
            <Route path="/roles/create" element={<RoleCreate />} />
            <Route path="/roles/edit/:id" element={<RoleEdit />} />
            <Route path="*" element={<Navigate to="/dashboard" />} />
          </>
        ) : (
          <>
            <Route path="/authentication/sign-in" element={<SignIn />} />
            <Route path="/test/hello" element={<SignIn />} />
            <Route path="/fetchLocation" element={<DepartmentFetchLocation />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/authentication/sign-up" element={<SignUp />} />
            <Route path="*" element={<Navigate to="/authentication/sign-in" />} />
          </>
        )}
      </Routes>
    </ThemeProvider>
  );
}
