import React, { useState, useContext, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import apiService from 'services/ApiService';
import { AuthContext } from 'context/AuthContext';
import moment from 'moment-timezone'; // Make sure this line is present
import TableCustomized from 'components/TableCustomized';
import { CircularProgress } from '@mui/material';
import MDButton from 'components/MDButton';

const timezone = 'Asia/Karachi'; // Set your desired timezone

function MarkAttendance() {
  const { user } = useContext(AuthContext);

  const [attendanceLogs, setAttendanceLogs] = useState();
  const [isSuccess, setIsSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const getAttendanceLogs = async () => {
    if (!user?.email) {
      setMessage('User email not found. Please log in.');
      return;
    }
    try {
      const response = await apiService.get('/api/v1/users/verifyToken');
      console.log('aTTENDANCE response', response);
      if (response.data) {
        setAttendanceLogs(response?.data?.data);
      }
    } catch (error) {
      console.error('Error fetching attendance logs:', error);
    }
  };

  useEffect(() => {
    getAttendanceLogs();
  }, [user]);

  // Helper function to get the user's current location (geolocation)
  const getCurrentLocation = () =>
    new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            resolve({ lat: latitude, lng: longitude });
          },
          (error) => {
            reject(error.message);
          }
        );
      } else {
        reject('Geolocation is not supported by this browser.');
      }
    });

  const handleAttendance = async (status) => {
    if (!user?.email) {
      setMessage('User email not found. Please log in.');
      return;
    }

    setLoading(true);
    setMessage('');

    try {
      const currentLocation = await getCurrentLocation();

      const response = await apiService.post('/api/v1/users/markAttendance', {
        email: user.email,
        status: status,
        currentLocation,
      });

      if (response.data.success) {
        setIsSuccess(true);

        setMessage(response.data.message);
        await getAttendanceLogs();
      } else {
        setIsSuccess(false);
        setMessage(response.data.message || 'Failed to mark attendance.');
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        setMessage(error.response.data.message);
      } else {
        setMessage('An error occurred while marking attendance.');
      }
    }

    setLoading(false);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <MDTypography variant="h6" color="white">
                  Mark Your Attendance
                </MDTypography>
              </MDBox>

              <MDBox pt={3} px={3}>
                {isSuccess ? (
                  <MDBox>
                    <MDTypography variant="h6" color="success">
                      {message}
                    </MDTypography>
                  </MDBox>
                ) : (
                  <MDTypography variant="h6" color="error">
                    {message}
                  </MDTypography>
                )}

                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <MDButton
                      variant="contained"
                      color="info"
                      onClick={() => handleAttendance('checkIn')}
                      fullWidth
                    >
                      {loading ? <CircularProgress color="white" size={'25px'} /> : 'Check In'}
                    </MDButton>
                  </Grid>

                  <Grid item xs={6}>
                    <MDButton
                      variant="contained"
                      color="info"
                      onClick={() => handleAttendance('checkOut')}
                      fullWidth
                    >
                      {loading ? <CircularProgress color="white" size={'25px'} /> : 'Check Out'}
                    </MDButton>
                  </Grid>
                </Grid>

                <MDBox mt={3}>
                  <TableCustomized attendanceLogs={attendanceLogs} />
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default MarkAttendance;
