import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif', lineHeight: '1.6' }}>
      <h1 style={{ textAlign: 'center', marginBottom: '20px' }}>Privacy Policy</h1>

      <h2>1. Introduction</h2>
      <p>
        Welcome to the Babtain Remote Attendance App. This platform streamlines attendance processes
        through face recognition and location-based technologies. Your privacy is important to us,
        and this Privacy Policy explains how we collect, use, share, and protect your information
        when you use our services.
      </p>

      <h2>2. Information We Collect</h2>
      <ul>
        <li>
          <strong>Personal Information:</strong> Includes your name, employee ID, department, and
          contact details provided during registration.
        </li>
        <li>
          <strong>Biometric Data:</strong> Facial recognition data for verifying attendance.
        </li>
        <li>
          <strong>Location Data:</strong> Your geographic location to confirm attendance eligibility
          within a specified radius of your department.
        </li>
        <li>
          <strong>Attendance Records:</strong> Logs of attendance, including timestamps, attendance
          status, and historical records that you can view within the app.
        </li>
        <li>
          <strong>Technical Information:</strong> Details about your device, including IP address,
          operating system, and usage data, to improve app functionality.
        </li>
      </ul>

      <h2>3. How We Use Your Information</h2>
      <ul>
        <li>
          <strong>Attendance Verification:</strong> To authenticate attendance using facial
          recognition and location data.
        </li>
        <li>
          <strong>Employee Records:</strong> To maintain accurate attendance logs accessible to you
          and authorized personnel.
        </li>
        <li>
          <strong>Attendance Reports:</strong> To enable you to view and analyze your attendance
          history.
        </li>
        <li>
          <strong>Service Improvement:</strong> To enhance app features and address technical
          issues.
        </li>
        <li>
          <strong>Compliance:</strong> To meet regulatory requirements or respond to lawful
          requests.
        </li>
      </ul>

      <h2>4. Sharing Your Information</h2>
      <ul>
        <li>
          <strong>With Your Consent:</strong> When you explicitly agree to share your information.
        </li>
        <li>
          <strong>Authorized Personnel:</strong> Attendance records may be accessible to HR or
          managerial staff for operational purposes.
        </li>
        <li>
          <strong>Service Providers:</strong> Trusted third-party providers assisting in app
          operations, who comply with our privacy standards.
        </li>
      </ul>

      <h2>5. Data Security</h2>
      <p>
        We use advanced security measures to protect your data, including encryption and secure
        storage. While we strive to ensure the highest levels of security, no method of data
        transmission or storage is completely secure.
      </p>

      <h2>6. Data Retention</h2>
      <p>
        Your information is retained for as long as necessary to fulfill the purposes outlined in
        this policy, or as required by law.
      </p>

      <h2>7. Your Rights</h2>
      <ul>
        <li>
          <strong>Access Your Information:</strong> Request access to your personal and attendance
          data.
        </li>
        <li>
          <strong>Update or Correct Information:</strong> Modify your personal details if
          inaccurate.
        </li>
        <li>
          <strong>Delete Your Information:</strong> Request deletion of your data, subject to legal
          retention requirements.
        </li>
        <li>
          <strong>Opt-Out:</strong> Opt-out of non-essential communications from us.
        </li>
      </ul>

      <h2>8. Changes to This Privacy Policy</h2>
      <p>
        We may update this Privacy Policy periodically. Any changes will be reflected on this page
        with an updated &quot;Effective Date.&quot; Please review this policy regularly to stay
        informed about how we protect your information.
      </p>

      <h2>9. Contact Us</h2>
      <p>
        If you have questions or concerns about this Privacy Policy, please contact us at:
        <br />
        <a href="mailto:admin@babtainattendance.com">admin@babtainattendance.com</a>
      </p>

      <p>
        By using the Babtain Remote Attendance App, you agree to the terms of this Privacy Policy.
        If you do not agree with these terms, please refrain from using our services.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
