import React, { createContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  let permission = {
    Admin: false,
    HrManager: false,
    HRBP: false,
    ItAdmin: false,
    Employee: false,
  };

  const setRole = () => {
    const { roles = [] } = user;
    const Admin = roles.filter((el) => el.name === "Admin");
    const HrManager = roles.filter((el) => el.name === "HR Manager");
    const HRBP = roles.filter((el) => el.name === "HRBP");
    const ItAdmin = roles.filter((el) => el.name === "IT Admin");
    const Employee = roles.filter((el) => el.name === "Employee");
    return {
      Admin: Admin.length === 1,
      HrManager: HrManager.length === 1,
      HRBP: HRBP.length === 1,
      ItAdmin: ItAdmin.length === 1,
      Employee: Employee.length === 1,
    };
  };

console.log('*************************Context user', user)

  // useEffect(() => {
  if (user) {
    permission = setRole();
  }
  // }, []);

  return (
    <AuthContext.Provider value={{ user, setUser, permission }}>{children}</AuthContext.Provider>
  );
};

// PropTypes validation for children
AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
