import { MenuItem, Select } from '@mui/material';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import apiService from 'services/ApiService';

export default function AssignManager({ department, fetchDepartmentsData }) {
  console.log('department', department);
  const [managers, setManagers] = useState([]);
  const [assignedManager, setAssignedManager] = useState(false);
  const [selectedManagerId, setSelectedManagerId] = useState('');

  const fetchManagers = async () => {
    try {
      const response = await apiService.get('/api/v1/users/managersList');
      console.log('-------------', response);
      if (response.status === 200) {
        setManagers(response.data.data);
      }
    } catch (error) {
      toast.error('Failed to fetch managers');
    }
  };

  useEffect(() => {
    fetchManagers();
  }, []);

  const handleAssignManager = async (departmentId, managerId) => {
    try {
      // Fetch the current department data
      const departmentResponse = await apiService.get(`/api/v1/departments/detail/${departmentId}`);
      console.log('departmentResponse', departmentResponse);

      if (departmentResponse.status === 200) {
        const departmentData = departmentResponse?.data?.department;

        // Find the selected manager's details
        const selectedManager = managers.find((manager) => manager._id === managerId);

        // If the manager is found, update hr_manager with manager details (name and email)
        if (selectedManager) {
          const updatedData = {
            hr_manager: [
              ...departmentData.hr_manager,
              {
                name: selectedManager.fullName,
                email: selectedManager.email,
              },
            ],
          };

          // Send the updated data back to the API
          const response = await apiService.put(
            `/api/v1/departments/update/${departmentId}`,
            updatedData
          );

          if (response.status === 200) {
            toast.success('Manager assigned successfully');
            fetchDepartmentsData();
            setAssignedManager(true); // Re-fetch data to reflect the changes
          } else {
            toast.error('Failed to update department');
          }
        } else {
          toast.error('Manager not found');
        }
      } else {
        toast.error('Failed to fetch department details');
      }
    } catch (error) {
      console.error('Error assigning manager:', error);
      toast.error('An error occurred while assigning the manager');
    }
  };

  return (
    <>
      {department?.hr_manager.length > 0 ? (
        <div className="flex justify-center">
          <span className="text-green-500">{department?.hr_manager[0].name}</span>
        </div>
      ) : (
        <Select
          value={selectedManagerId}
          //   value={department?.hr_manager[0].name || ''}
          onChange={(e) => {
            const managerId = e.target.value;
            setSelectedManagerId(managerId); // Update the new state
            handleAssignManager(department._id, managerId); // Call the existing function
          }}
          displayEmpty
          fullWidth
        >
          <MenuItem disabled value="">
            <em>Assign Manager</em>
          </MenuItem>
          {managers && managers.length > 0 ? (
            managers.map((manager, idx) => (
              <MenuItem key={idx} value={manager._id}>
                {manager.fullName}
              </MenuItem>
            ))
          ) : (
            <MenuItem disabled>No Managers Available</MenuItem>
          )}
        </Select>
      )}
    </>
  );
}
