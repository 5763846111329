import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import apiService from '../../services/ApiService';
import { toast } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, TextField, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const ShiftsData = [
  {
    label: 'Single Shift',
    value: 'SS',
  },
  {
    label: 'Double Shift',
    value: 'DS',
  },
  {
    label: 'Over Night',
    value: 'ON',
  },
];

function Edit() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    shiftName: '',
    slotType: '',
    startTime: null,
    endTime: null,
  });
  console.log('formData', formData);
  const [companies, setCompanies] = useState([]);
  const handleTimeChange = (name, newValue) => {
    setFormData({
      ...formData,
      [name]: newValue,
    });
  };

  useEffect(() => {
    const fetchBusinessUnit = async () => {
      try {
        const response = await apiService.get(`/api/v1/shifts/detail/${id}`);
        console.log('response', response);
        const unit = response.data.shift;
        setFormData({
          shiftName: unit.shiftName || '',
          slotType: unit.shiftType || '',
          startTime: unit.startTime && new Date(unit.startTime),
          endTime: unit.endTime && new Date(unit.endTime),
        });
        console.log('formData', formData);
      } catch (error) {
        toast.error('Failed to fetch Slot data');
      }
    };

    fetchBusinessUnit();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!formData.startTime || !formData.endTime) {
        toast.error('Please select both start and end times.');
        return;
      }
      const payload = {
        ...formData,
        startTime: formData.startTime.toISOString(),
        endTime: formData.endTime.toISOString(),
      };
      const response = await apiService.put(`/api/v1/shifts/update/${id}`, payload);
      if (response.status === 200) {
        navigate('/shifts');
        toast.success('Shift updated successfully');
      }
    } catch (error) {
      toast.error(error.response?.data?.message || 'Failed to update slot');
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box
          sx={{
            // display: 'flex',
            // justifyContent: 'center',
            // alignItems: 'flex-start',
            minHeight: '100vh',
            bgcolor: '#f5f5f5',
            padding: 3,
          }}
        >
          <Card
            sx={{
              width: '100%',

              padding: 4,
              boxShadow: 3,
            }}
          >
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <MDTypography variant="h6" color="white">
                Edit Shift
              </MDTypography>
            </MDBox>

            <Box pt={3} px={2} component="form" onSubmit={handleSubmit}>
              {/* Shift Name Field */}
              <Box mb={3}>
                <TextField
                  label="Shift Name *"
                  name="shiftName"
                  variant="standard"
                  fullWidth
                  value={formData.shiftName}
                  onChange={handleInputChange}
                />
              </Box>

              {/* Select Shift Type */}
              <Box mb={3}>
                <InputLabel id="shift-type-label">Select Shift Type *</InputLabel>
                <Select
                  labelId="shift-type-label"
                  name="slotType"
                  value={formData.slotType}
                  onChange={handleInputChange}
                  fullWidth
                  variant="standard"
                >
                  {ShiftsData.map((shift) => (
                    <MenuItem key={shift.label} value={shift.value}>
                      {shift.label}
                    </MenuItem>
                  ))}
                </Select>
              </Box>

              {/* Start Time Field */}
              <Box mb={3}>
                <TimePicker
                  label="Start Time *"
                  value={formData.startTime}
                  onChange={(newValue) => handleTimeChange('startTime', newValue)}
                  renderInput={(params) => <TextField {...params} variant="standard" fullWidth />}
                />
              </Box>

              {/* End Time Picker */}
              <Box mb={3}>
                <TimePicker
                  label="End Time *"
                  value={formData.endTime}
                  onChange={(newValue) => handleTimeChange('endTime', newValue)}
                  renderInput={(params) => <TextField {...params} variant="standard" fullWidth />}
                />
              </Box>

              {/* Submit Button */}
              <Box mt={4}>
                <MDButton variant="gradient" color="info" type="submit">
                  Save Changes
                </MDButton>
              </Box>
            </Box>
          </Card>
        </Box>
      </LocalizationProvider>
    </DashboardLayout>
  );
}

export default Edit;
