import axios from 'axios';
let token = localStorage.getItem('auth-token');
axios.defaults.headers.common['uuid'] = token;
axios.defaults.headers.common['authorization'] = token;
// axios.defaults.baseURL = "http://143.198.198.126:9000";
// axios.defaults.baseURL = "http://61c5-39-42-100-55.ngrok-free.app";
// axios.defaults.baseURL = 'http://localhost:8000';
axios.defaults.baseURL = 'https://babtainnotify.com:81';
axios.defaults.headers.post['Content-Type'] = 'application/json';
let axiosInstance = axios;
export default axiosInstance;
